import React, { useEffect, memo, Suspense } from 'react';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo
import RedesSocialesButton from './RedesSocialesButton';
import PayInfoButton from './PayInfoButton';
import ReservaHomeButton from './ReservaHomeButton';
import Logo from './Logo';
import { MdVerified } from "react-icons/md";
import ContactButtons from './ContactButtons';

const CompanyDetails = ({ name, title, shortDescription, linkPay }) => (
  <div className='px-6 lg:px-32 py-8'>
    <div className='flex flex-col justify-center items-center'>
      <Logo size={80} aria-label="Company Logo" />
      <div className='flex flex-row items-center justify-center space-x-2'>
        <h2 className='my-2 text-lg md:text-3xl font-semibold text-gray-800' role="heading" aria-level="2">{title}</h2>
        <MdVerified className='text-blue-600 text-xl md:text-2xl' aria-label="Verified" />
      </div>
    </div>
    <p className="font-semibold text-base md:text-lg pb-8">{name}</p>
    <p className="text-sm md:text-base text-start">{shortDescription}</p>
  </div>
);

const CompanyActions = ({ linkPay }) => (
  <div
    className="bg-cover bg-center rounded-r-lg"
    style={{
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/splash-screen.jpg)',
    }}
    aria-label="Company Background Image"
  >
    <div className="text-lg md:px-12 flex flex-col items-center justify-center h-full py-8">
      <ContactButtons />
      <div className="text-center">
        {linkPay && <PayInfoButton />}
      </div>
      <div className="text-center mt-2">
        <ReservaHomeButton />
      </div>
      <RedesSocialesButton />
    </div>
  </div>
);

const CompanyInfo = () => {
  const companyInfo = useCompanyInfo();

  useEffect(() => {
    if (companyInfo) {
      document.title = companyInfo.title;
    }
  }, [companyInfo]);

  if (!companyInfo) {
    return <div>Cargando información de la compañía...</div>;
  }

  const { name, title, shortDescription, linkPay } = companyInfo;

  return (
    <div className="text-center mt-12 bg-white rounded-xl w-full">
      <div className='grid md:grid-cols-2 gap-4'>
        <CompanyDetails
          name={name}
          title={title}
          shortDescription={shortDescription}
          linkPay={linkPay}
        />
        <CompanyActions linkPay={linkPay} />
      </div>
    </div>
  );
}

export default memo(CompanyInfo);
